import React from "react"

import Layout from "../components/layout"
import SEO from "../components/seo"

const EventRegistrationsComponent = props => {
  return (
    <Layout location={props.location} title={props.pageContext.display_name}>
      <SEO title="Optotax Webinar" />
      <div>
        <div className="flex-around iframe-form-container">
          {props.pageContext.reg_link.length > 0 && (
            <iframe
              style={{ position: "relative", height: "100%", border: "none" }}
              src={`${props.pageContext.reg_link}/viewform?embedded=true`}
              height="100%"
              width="100%"
              frameBorder="0"
              marginheight="0"
              marginwidth="0"
            >
              Loading…
            </iframe>
          )}
        </div>
      </div>
    </Layout>
  )
}
export default EventRegistrationsComponent
